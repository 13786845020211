footer {
  height: 82vh;
  width: 100vw;
  .footer-logo {
	height: 100px;
}

  .footer-info {
    width: 100%;
    height: 100%;
    background: #05445E;
    color: var(--background-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2rem;

  }

  .footer-social {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .footer-social .logo-content {
    width: 63rem;
  }

  .footer-contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  .footer-mobile {
    padding: 0.5rem;
    margin: 0.5rem;
    width: 300px;
    height: 100px;
  }

  .footer-offices {
    margin: 0.5rem;
    padding: 0.5rem;
    width: 300px;
    height: 100px;

  }

  .footer-offices ul li {
    font-size: 0.8rem;
  }

  .footer-mobile ul li {
    font-size: 0.8rem;
  }

  .copyright {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    background: #044c6b;
    font-size: 0.8rem;
  }

  .copyright p{
    color: var(--background-color);
  }
}

@media only screen and (max-width: 609px) {
  footer {
    height: 150vh;
  }

  .footer-logo {
    height: 30px;
    width: 30px;
  }

  .footer-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
  }
}
  