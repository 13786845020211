@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700;900&display=swap");

/*
=============== 
Variables
===============
*/

:root {
	--text-color: #000000;
	--text-hover-color: #C2C2C2;
	--background-color: #fff;
	--transition: all 0.3s linear;
	--spacing: 0.1rem;
	--radius: 0.25rem;
	--light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	--dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	--max-width: 1170px;
	--fixed-width: 620px;
}
/*
  =============== 
  Global Styles
  ===============
  */

*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: "Poppins", sans-serif;
	background: var(--background-color);
	color: var(--text-color);
	line-height: 1.5;
	font-size: 0.875rem;
}
ul {
	list-style-type: none;
}
a {
	text-decoration: none;
}
h1,
h2,
h3,
h4 {
	letter-spacing: var(--spacing);
	text-transform: capitalize;
	line-height: 1.25;
	margin-bottom: 0.75rem;
}
h1 {
	font-size: 3rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.25rem;
}
h4 {
	font-size: 0.875rem;
}
p {
	color: var(--text-color);
}

@media screen and (min-width: 800px) {
	h1 {
		font-size: 4rem;
	}
	h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 1.75rem;
	}
	h4 {
		font-size: 1rem;
	}
	body {
		font-size: 1rem;
	}
	h1,
	h2,
	h3,
	h4 {
		line-height: 1;
	}
}
/*  global classes */

/* section */
.section {
	width: 100vw;
	margin: 0 auto;
	max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
	.section {
		width: 95vw;
	}
}

/*
  =============== 
  Navbar
  ===============
  */
nav {
	position: fixed;
	width: 100vw;
	background: #05445E;
	z-index: 999;
}
.nav-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
}
.nav-toggle {
	font-size: 1.5rem;
	background: transparent;
	border-color: transparent;
	transition: var(--transition);
	cursor: pointer;
}
.nav-toggle:hover {
	color: var(--background-color);
	transform: rotate(90deg);
}
.logo {
	height: 80px;
}
.links a {
	color: var(--background-color);
	font-size: 1rem;
	text-transform: capitalize;
	letter-spacing: var(--spacing);
	display: block;
	padding: 0.5rem 1rem;
	transition: var(--transition);
}

.social-icons {
	 display: flex;
   justify-content: center;
   align-items: center;
}
.links-container {
	height: 0;
	overflow: hidden;
	transition: var(--transition);
}
.show-container {
	height: 10rem;
}
@media screen and (min-width: 800px) {
	.nav-center {
		max-width: 1170px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
	}
	.nav-header {
		padding: 0;
	}
	.nav-toggle {
		display: none;
	}
	.links-container {
		height: auto !important;
	}
	.links {
		display: flex;
	}
	.links a {
		padding: 0;
		margin: 0 0.5rem;
	}
	.links a:hover {
		padding: 0;
		background: transparent;
		opacity: 0.5;
		color: var(--text-hover-color);
		border-bottom: 2px solid var(--text-hover-color);
		transition: var(--transition);
		cursor: pointer;
	}
	.social-icons {
		display: flex;
	}
	.social-icons a {
		margin: 0 0.5rem;
		color: var(--background-color);
		transition: var(--transition);
	}
	.social-icons a:hover {
		color: var(--text-hover-color);
	}
}

.contact {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.not-found {
	width: 200px;
	height: 200px;
}

.whatsapp-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #25d366;

	z-index: 999;
	cursor: pointer;
	border: none !important;
	overflow: hidden;
	outline: none;

	position: fixed;
	bottom: 0;
	right: 0;
	margin: 1rem;
}
