.contact {
  max-width: 100vw;
  width: 100%;
  height: 100vh;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  background: url('../../assets/img/contact-background.svg') 100% no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-form {
  background: #F4F9FF;
  padding: 5rem;
  border-radius: 50px;
  width: 75vw;
  height: 70vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.contact-form p {
  color: #757575;
  margin: 1rem;
}

.contact-form h3 {
  margin: 1rem;
}

input {
  width: 22rem;
  border-radius: 25px;
  padding: 1.5rem;
  margin: 1rem;
  border: none;
  color: #757575;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

textarea {
  width: 22rem;
  height: 150px;
  box-sizing: border-box;
  resize: none;
  border-radius: 25px;
  padding: 1.5rem;
  margin: 1rem;
  border: none;
  color: #757575;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.input-form {
  width: 100%;
}

button {
  background: #263D6A;
  color: #FFF;
  padding: 1rem;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  margin: 0.5rem;
}
 .success-message {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

 .icon {
  height: 100px;
  width: 100px;
  margin: 1rem
 }

 @media screen and (max-width: 800px) {
    input, textarea {
      width: 200px;
      height: 10px;
    }
 }

 @media only screen and (max-width: 609px) {
  .contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;

  }
}