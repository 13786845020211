.card-container {
  width: 90vw;
  height: auto !important;
  max-width:  1170px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-description p{
    padding-left: 2rem;
    padding: 1rem;
    text-align: left;
}

.card-description h3 {
  padding-left: 2rem;
  padding: 1rem;
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}

.card-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border-radius: 1rem;
  margin-bottom: 1rem; 
}

.card-title {
  text-align: center;
  font-weight: bold;
  font-size: 6rem;
  background: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-number {
  text-align: left;
  font-size: 2rem;
  background: #263D6A;
  color: #FFF;
  padding: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -4rem;
  margin-top: -4rem;
}

.card {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: #FFF;
  border-radius: 12px;
  border: 1px solid #c2c2c24f;
  width: 30rem;
  height: 25rem;
  padding: 2rem;
  margin: 2rem;
}

.card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 609px) {
  .card-main-content{
    margin: 1rem;
    padding: 2rem;
  }

  .card {
    font-size: 12pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}