.service {
  width: 90%;
  height: 100%;
  overflow-x: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-container {
    max-width: 100vw;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    padding: 3rem;
    margin-left: 15rem;
    margin-bottom: 2rem;
}

.service-container p {
  color: #565656;
  text-align: left;
}


@media screen and (max-width: 800px) {
    .service {
       max-width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      
      }
}

.ceo-content {
  width: 80vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem; 
}

.ceo-content div {
  padding: 1rem;
  margin: 1rem;
}

.ceo-img {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 300px;
  height: 400px
}

span {
  font-weight: bold;
}

@media only screen and (max-width: 609px) {
  .service {
    margin-top: 50rem;
  }
  .ceo-content {
    font-size: 12pt;
    display: block;
  }
  .service-container {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}