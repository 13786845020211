.carroussel {
    width: 100vw;
    height: 100vh;
}

// .about-description {
//     text-align: center;
// }

// .about-description h3 {
//     font-size: 4rem;
//     font-weight: 700;
// }

// .about-description p {
//     font-size: 13pt;
//     text-indent: center;
//     color: var(--default-color);
// }

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #F4F9FF;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .imgSlider {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 13rem;
  }
  