.partner {
    width: 100vw;
    height: 110vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url('../../assets/img/bg-2.svg') no-repeat 30%;
}

@media only screen and (max-width: 609px) {
  .partner {
    visibility: hidden;
  }

}

