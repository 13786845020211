.about {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container {	
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.container div {
	width: 300px;
	height: 400px;
	display: flex;

	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-radius: 10px;
	padding: 1rem;
	margin: 0.5rem;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.content-description {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.content div {
	width: 400px;
	height: 500px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 1rem;
}

@media only screen and (max-width: 609px) {
	body {
		margin:  0;
		padding: 0;
	}
  .about {
		display: block;
		justify-content: center;
		align-items: center;
		height: auto;
  }

	.container {
		display: block;
		justify-content: center;
		align-items: center;
  }
}